export default function toggleFields() {
  document.querySelectorAll('[data-toggle-target]').forEach((wrapper) => {
    let target = document.querySelector(wrapper.dataset.toggleTarget);
    let value = wrapper.dataset.toggleValue;

    wrapper.querySelectorAll('input, select').forEach((node) => {
      node.addEventListener('change', function() {
        if (this.value == value) {
          target.classList.remove('hidden');
        } else {
          target.classList.add('hidden');
        }
      });
    });
  });
}
